import React from 'react'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { theme } from '../styles/theme'

const Card = ( props ) => {
    const { title, slug, description, thumbnail, order } = props

    const stylez = css`
        font-weight: 400;
        margin-bottom: 3rem;
        ${ theme.breakpoints.mobile } {
            margin-bottom: 3rem;
          }
        .card_image_wrapper {
          margin-bottom: 1rem;
            transition: transform 300ms ease-in-out;
          :hover {
            transform: scale(1.005);
          }
          ${ theme.breakpoints.mobile } {
            margin-bottom: 0.5rem;
            :hover {
              transform: none;
            }
          }
        }
        .card_text_wrapper {
          h1 {
            font-size: 1.5rem;
            font-weight: 300;
            width: fit-content;
            :hover{
              border-bottom: 1px solid black;
              padding-bottom: 5px;
              margin-bottom: -6px;
              width: -moz-fit-content;
            }
            ${ theme.breakpoints.mobile } {
              font-size: 1.2rem;
              }
              ${ theme.breakpoints.largeDesktop } {
              font-size: 1.5rem;
              }
          }
        }
        .flex {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

    `

    return (
        <div
          className='card'
          css={ stylez }
        >
            <Link to={ `/${ slug }` }>
                {/* <div className='flex'> */}
                <div className='card_image_wrapper'>
                    <Img
                      fluid={ thumbnail.fluid }
                      alt={ title }
                    />
                </div>
                <div className='card_text_wrapper'>
                    {
                        title && <h1>{title}</h1>
                    }
                    {/* {
                        description && <p>{description.description}</p>
                    } */}

                </div>
                {/* </div> */}
            </Link>
        </div>
    )
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    description: PropTypes.shape( {
        description: PropTypes.string,
    } ),
}

export default Card

export const query = graphql`
  fragment ProjectCard on ContentfulProject {
    id
    title
    slug
    order
    thumbnail {
      fluid(maxWidth: 1280, quality: 90) {
        ...GatsbyContentfulFluid
      }
    }
    description {
      description
    }
  }
`
