/** @jsx jsx */
import { graphql } from 'gatsby'
import React from 'react'
import { css, jsx } from '@emotion/react'
import Cards from '../components/Cards'
import Layout from '../layouts/Layout'
import SiteMetadata from '../components/SiteMetadata'

const IndexPage = ( { data } ) => {
    const stylez = css`

    `

    return (
        <Layout>
            <SiteMetadata title='Sarah Wiltschek' />

            <div
              className='index'
              css={ stylez }
            >
                {data.portfolio && data.portfolio.nodes.length > 0
                    ? (
                        <Cards items={ data.portfolio.nodes } />
                    )
                    : (
                        <div className='no_project'>Keine Projekte</div>
                    )}
            </div>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    portfolio: allContentfulProject {
      nodes {
        ...ProjectCard
      }
    }
  }
`
