import PropTypes from 'prop-types'
import React from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import Card from './Card'
import { theme } from '../styles/theme'

const Cards = ( { items, hideLastItemOnMobile = false } ) => {
    const stylez = css`
        width: 75%;
        margin: 0 auto;
            ${ theme.breakpoints.mobile } {
            width: 95%;
            }
            ${ theme.breakpoints.tablet } {
            width: 85%;
            }
            ${ theme.breakpoints.largeDesktop } {
            width: 75%;
            }

    `

    return (
        <div
          className='cards_wrapper'
          css={ stylez }
        >

            {items.sort( ( a, b ) => a.order - b.order ).map( item => (
                <div
                  className='card_container'
                  key={ item.id }
                >
                    <Card { ...item } />
                </div>
            ) )}

        </div>
    )
}

Cards.propTypes = {
    items: PropTypes.arrayOf( PropTypes.object ).isRequired,
}

export default Cards
